import {
  Switch,
  Tooltip,
  Box,
  Button,
  Chip,
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { IRootState } from 'config/store';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PrivateComponent from 'shared/auth/privateComponent';
import { IRule } from 'shared/model/rule.model';
import { deleteRule, fetchRules, updateRule } from 'shared/reducers/rulesSlice';
import { APP_TIMESTAMP_FORMAT, formatDate } from 'shared/utils/date-utils';
import ConfirmDelete from 'shared/widgets/confirmDelete';
import DataTable from 'shared/widgets/dataTable';
import TitleWithSearchField from 'shared/widgets/titleWithSearchField';
import UsersAvatar from 'shared/widgets/usersAvatar';
import RuleDetails from './ruleDetails';
import RuleIcon from './ruleIcons';
import { useIsAuthorised } from 'shared/auth/auth-utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contextToolBar: {
      '&>*': {
        marginLeft: theme.spacing(1)
      }
    },
    icon: {
      width: '32px',
      height: '32px'
    },
    chip: {
      margin: theme.spacing(0.5),
      borderRadius: '10px'
    }
  })
);

const Rules = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const rules = useSelector(({ rules }: IRootState) => rules.rules);
  const loading = useSelector(({ rules }: IRootState) => rules.loading);
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [filterText, setFilterText] = React.useState('');

  const [selectedRows, setSelectedRows] = useState<IRule[]>([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const columns: IDataTableColumn<IRule>[] = useMemo(() => {
    const handleStatusChange = (rule: IRule) => () => {
      const toUpdate = {
        id: rule.id,
        is_enabled: !rule.is_enabled
      };
      dispatch(updateRule(toUpdate, true));
    };
    return [
      {
        selector: 'resourcetype',
        name: t('resourcetype'),
        sortable: true,
        grow: 0.5,
        hide: 'sm',
        format: (row: IRule) => <RuleIcon type={row.resourcetype} />
      },
      {
        selector: 'name',
        name: t('name'),
        sortable: true,
        grow: 1.5
      },
      {
        selector: 'last_triggered_time',
        name: t('last_triggered_time'),
        sortable: true,
        grow: 1,
        hide: 'md',
        format: (row: IRule) => {
          if (row.last_triggered_time) {
            const fromNow = row.last_triggered_time.fromNow();
            const fullDate = formatDate(row.last_triggered_time, APP_TIMESTAMP_FORMAT);
            return (
              <Tooltip title={fullDate}>
                <Box>{fromNow}</Box>
              </Tooltip>
            );
          }
          return null;
        }
      },
      {
        selector: 'watched_groups',
        name: t('watched_groups'),
        sortable: true,
        grow: 2,
        hide: 'md',
        format: (row: IRule) => {
          const extraGroupsCount =
            row.watched_groups.length > 5 ? row.watched_groups.length - 5 : 0;
          return (
            <Box display="flex" width="100%" flexWrap="wrap" py={1}>
              {row.watched_groups.slice(0, 5).map(g => (
                <Chip
                  key={g.group_id}
                  label={g.group_name}
                  variant="outlined"
                  className={classes.chip}
                  size="small"
                />
              ))}
              {extraGroupsCount ? (
                <Tooltip
                  title={
                    extraGroupsCount
                      ? row.watched_groups
                          .slice(5)
                          .map(g => g.group_name)
                          .join(', ')
                      : ''
                  }
                  arrow
                >
                  <Chip
                    label={`+${extraGroupsCount}`}
                    variant="outlined"
                    className={classes.chip}
                    size="small"
                  />
                </Tooltip>
              ) : null}
            </Box>
          );
        }
      },
      {
        selector: 'recipients_users',
        name: t('recipients_users'),
        sortable: true,
        grow: 1.5,
        hide: 'md',
        format: (row: IRule) => {
          if (row.recipients_users.length) {
            return (
              <Box py={1}>
                <UsersAvatar users={row.recipients_users} />
              </Box>
            );
          }
          return null;
        }
      },
      {
        selector: 'is_enabled',
        name: t('rules_status'),
        sortable: true,
        grow: 0.5,
        format: (row: IRule) => {
          return (
            <PrivateComponent
              resource="Rule"
              operation={['UPDATE']}
              otherwise={<Switch checked={row.is_enabled} disabled />}
            >
              <Switch checked={row.is_enabled} color="primary" onChange={handleStatusChange(row)} />
            </PrivateComponent>
          );
        }
      }
    ];
  }, [classes.chip, dispatch, t]);

  useEffect(() => {
    dispatch(fetchRules());
  }, [dispatch]);

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const actions = (
    <PrivateComponent resource="Rule" operation={['CREATE']}>
      <Button color="primary" variant="contained" component={Link} to="/rule/new" size="small">
        <AddIcon />
      </Button>
    </PrivateComponent>
  );

  const contextActions = useMemo(() => {
    const onSuccess = () => {
      dispatch(fetchRules());
      setToggleCleared(!toggleCleared);
    };

    const handleDelete = async () => {
      for (let i = 0; i < selectedRows.length; i++) {
        await dispatch(deleteRule(selectedRows[i]));
      }
      onSuccess();
    };

    return (
      <Box className={classes.contextToolBar}>
        {selectedRows.length === 1 && (
          <PrivateComponent resource="Rule" operation={['UPDATE']}>
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to={`/rule/${selectedRows[0].id}`}
            >
              <EditIcon />
            </Button>
          </PrivateComponent>
        )}
        <PrivateComponent resource="Rule" operation={['DELETE']}>
          <ConfirmDelete onConfirm={handleDelete} objectToReturn={selectedRows} size="small" />
        </PrivateComponent>
      </Box>
    );
  }, [classes.contextToolBar, dispatch, selectedRows, toggleCleared]);

  const title = React.useMemo(() => {
    const onChange = (filter: string) => {
      setFilterText(filter);
      if (filter.length === 0) {
        setResetPaginationToggle(!resetPaginationToggle);
      }
    };

    return (
      <TitleWithSearchField
        title={t('business_rules')}
        placeholder={t('name')}
        onChange={onChange}
        autoFocus
      />
    );
  }, [resetPaginationToggle, t]);

  const data =
    rules.length > 0
      ? rules.filter(item => {
          const name = item.name.toLocaleLowerCase();
          const filter = filterText.toLocaleLowerCase();
          return name.includes(filter);
        })
      : rules;

  return (
    <Box p={1}>
      <DataTable
        title={title}
        columns={columns}
        data={data}
        selectableRows={useIsAuthorised('Rule', ['DELETE', 'UPDATE'])}
        defaultSortField="name"
        progressPending={loading}
        paginationPerPage={10}
        onSelectedRowsChange={handleRowSelected}
        expandableRows
        expandableRowsComponent={<RuleDetails />}
        expandOnRowClicked
        expandableRowsHideExpander
        actions={actions}
        contextActions={contextActions}
        clearSelectedRows={toggleCleared}
      />
    </Box>
  );
};

export default Rules;
