import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IRootState } from 'config/store';
import MissingInfoField from 'modules/devices/fields/MissingInfoField';
import React, { useCallback, useMemo, useState } from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PrivateComponent from 'shared/auth/privateComponent';
import {
  DeviceStatusFilter,
  IDevice,
  DeviceBatteryStatusFilter,
  DeviceStatus
} from 'shared/model/device.model';
import { fetchDevices, updateDevicesWithGroup } from 'shared/reducers/devicesSlice';
import { APP_LOCAL_DATE_FORMAT, formatDate } from 'shared/utils/date-utils';
import { ILabelValueOption } from 'shared/utils/select-utils';
import { workspaceIsIbc, workspaceIsSilo } from 'shared/utils/workspace-utils';
import DataTable from 'shared/widgets/dataTable';
import DeviceFilter from 'shared/widgets/devices/deviceFilter';
import DeviceName from 'shared/widgets/devices/deviceName';
import GroupFilter from 'shared/widgets/groups/groupFilter';
import TitleWithSearchField from 'shared/widgets/titleWithSearchField';
import CombineBtn from './actions/combineBtn';
import UnCombineBtn from './actions/unCombineBtn';
import DeviceDetails from './deviceDetails';
import AddGroupToDeviceDialog from './dialogs/addGroupToDeviceDialog';
import EditDeviceDialog from './dialogs/editDeviceDialog';
import ExportDeviceCsvDialog from './dialogs/exportDeviceCsvDialog';
import CapaMaxField from './fields/capaMaxField';
import GroupCell from './GroupCell';
import AssignPoi from 'modules/devices/actions/AssignPoi';
import { Check } from '@material-ui/icons';
import BatteryFilter from 'shared/widgets/devices/batteryFilter';
import { useIsAuthorised } from 'shared/auth/auth-utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contextToolBar: {
      '&>*': {
        marginLeft: theme.spacing(1)
      }
    },
    statusFilter: {
      height: '29px',
      minWidth: '5rem',
      marginLeft: theme.spacing(2)
    }
  })
);

const defaultSort = 'device_name';

const Devices = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const devices = useSelector(({ devices }: IRootState) => devices.devices);
  const loading = useSelector(({ devices }: IRootState) => devices.loading);
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const isIbc = workspaceIsIbc(settings);
  const isSilo = workspaceIsSilo(settings);

  const [selectedRows, setSelectedRows] = useState<IDevice[]>([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [statusFilter, setStatusFilter] = useState<DeviceStatusFilter>('empty');
  const [statusBatteryFilter, setStatusBatteryFilter] = useState<DeviceBatteryStatusFilter>('null');
  const [groupsFilter, setGroupsFilter] = useState<string[]>([]);

  const columns: IDataTableColumn<IDevice>[] = useMemo(() => {
    const columnName: IDataTableColumn<IDevice> = {
      selector: 'device_name',
      name: t('device_name'),
      sortable: true,
      grow: 3,
      format: (row: IDevice) => <DeviceName device={row} />
    };
    const columnGroups: IDataTableColumn<IDevice> = {
      selector: 'group_ids',
      name: t('group', { count: 2 }),
      grow: 3,
      hide: 'sm',
      right: true,
      format: (row: IDevice) => <GroupCell groups={row.groups} />
    };
    const columnContent: IDataTableColumn<IDevice> = {
      selector: 'device_content',
      name: t('device_content'),
      sortable: false,
      grow: 2
    };
    const columnMissingInfo: IDataTableColumn<IDevice> = {
      selector: row => row.metadata?.silo_legs,
      name: t('devices_status.status'),
      sortable: false,
      grow: 2,
      // @ts-ignore
      format: (row: IDevice) => <MissingInfoField device={row} />
    };
    const columnCapaMax: IDataTableColumn<IDevice> = {
      selector: 'capa_max',
      name: t('capacity'),
      sortable: true,
      grow: 1,
      format: (row: IDevice) => <CapaMaxField device={row} />
    };
    const columnColor: IDataTableColumn<IDevice> = {
      selector: 'color',
      name: t('color'),
      sortable: true,
      grow: 0,
      width: '80px',
      format: (row: IDevice) => (
        <Box bgcolor={row.color} width="20px" height="20px" borderRadius="10px"></Box>
      )
    };
    const columnModel: IDataTableColumn<IDevice> = {
      selector: 'model',
      name: t('model'),
      sortable: false,
      grow: 2
    };
    const columnAutoAssign: IDataTableColumn<IDevice> = {
      selector: 'galileou_enabled',
      name: t('galileou_enabled'),
      sortable: false,
      grow: 0,
      width: '100px',
      center: true,
      format: (row: IDevice) => row.galileou_enabled && <Check />
    };
    const columnNextDelivery: IDataTableColumn<IDevice> = {
      selector: 'device_next_delivery',
      name: t('device_next_delivery'),
      sortable: false,
      grow: 1,
      format: (row: IDevice) => formatDate(row.device_next_delivery, APP_LOCAL_DATE_FORMAT)
    };

    if (isIbc) {
      return [
        //
        columnName,
        columnColor,
        columnModel,
        columnContent,
        columnGroups,
        columnAutoAssign
      ];
    } else {
      return [
        columnName,
        columnMissingInfo,
        columnContent,
        columnNextDelivery,
        columnCapaMax,
        columnGroups
      ];
    }
  }, [isIbc, t]);

  const title = React.useMemo(() => {
    const onChange = (filter: string) => {
      setSearchText(filter);
      if (filter.length === 0) {
        setResetPaginationToggle(!resetPaginationToggle);
      }
    };

    const onStatusChange = (event: React.ChangeEvent<any>) => {
      setStatusFilter(event.target.value);
    };
    const onStatusBatteryChange = (event: React.ChangeEvent<any>) => {
      setStatusBatteryFilter(event.target.value);
    };

    const onGroupsChange = (selection: ILabelValueOption<string>[]) => {
      const filter = selection.map(item => item.value) as string[];
      setGroupsFilter(filter);
    };

    return (
      <TitleWithSearchField
        title={isIbc ? t('ibc', { count: 100 }) : t('silo', { count: 100 })}
        placeholder={t('name')}
        onChange={onChange}
        autoFocus
        debounceWait={300}
        loading={loading}
      >
        <DeviceFilter status={statusFilter} onChange={onStatusChange} />
        {!isIbc && <BatteryFilter status={statusBatteryFilter} onChange={onStatusBatteryChange} />}
        <GroupFilter onChange={onGroupsChange} />
      </TitleWithSearchField>
    );
  }, [t, loading, statusFilter, statusBatteryFilter, resetPaginationToggle, isIbc]);

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const onSuccess = async () => {
      await dispatch(fetchDevices());
      await dispatch(updateDevicesWithGroup());
      setToggleCleared(!toggleCleared);
    };

    return (
      <Box className={classes.contextToolBar}>
        {isIbc && <AssignPoi devices={selectedRows} onSuccess={onSuccess} />}
        {selectedRows.length === 1 && (
          <PrivateComponent resource="Device" operation={['UPDATE']}>
            <EditDeviceDialog device={selectedRows[0]} onSuccess={onSuccess} />
          </PrivateComponent>
        )}
        {isSilo && (
          <>
            {selectedRows.length === 1 && (
              <PrivateComponent resource="Device" operation={['DELETE']}>
                <UnCombineBtn device={selectedRows[0]} onSuccess={onSuccess} />
              </PrivateComponent>
            )}
            {selectedRows.length > 1 && (
              <PrivateComponent resource="Device" operation={['CREATE']}>
                <CombineBtn devices={selectedRows} onSuccess={onSuccess} />
              </PrivateComponent>
            )}
          </>
        )}
        <PrivateComponent resource="Device" operation={['UPDATE']}>
          <AddGroupToDeviceDialog devices={selectedRows} onSuccess={onSuccess} />
        </PrivateComponent>
        <ExportDeviceCsvDialog devices={selectedRows} />
      </Box>
    );
  }, [classes.contextToolBar, selectedRows, isSilo, isIbc, dispatch, toggleCleared]);

  // HANDLE FILTER PART
  const data = devices.filter(device => {
    const possibleStatus: Partial<Record<DeviceStatusFilter, DeviceStatus[]>> = {
      ok: ['ok'],
      pending: ['pending', 'calibrating', 'level_problem'],
      error: ['problem', 'calibration_problem', 'error']
    };

    // Filtrer par statut
    if (possibleStatus[statusFilter]) {
      if (!possibleStatus[statusFilter].includes(device.status)) {
        return false;
      }
    }

    if (
      groupsFilter.length > 0 &&
      !device.groups?.some(group => groupsFilter.includes(group.group_id))
    ) {
      return false;
    }

    // Filtrer par statut de batterie
    if (statusBatteryFilter !== 'null') {
      if (device.metadata?.battery_status !== statusBatteryFilter) return false;
    }

    // Filtrer par texte de recherche
    if (searchText.length > 0) {
      if (
        !device.device_name.toLowerCase().includes(searchText.toLowerCase()) &&
        !device.device_reference.toLowerCase().includes(searchText.toLowerCase())
      ) {
        return false;
      }
    }

    return true;
  });
  return (
    <Box p={1}>
      <DataTable
        title={title}
        columns={columns}
        data={data}
        paginationPerPage={20}
        selectableRows={useIsAuthorised('Device', ['DELETE', 'UPDATE'])}
        defaultSortField={defaultSort}
        progressPending={loading}
        expandableRows
        expandableRowsComponent={<DeviceDetails />}
        expandOnRowClicked
        expandableRowsHideExpander
        onSelectedRowsChange={handleRowSelected}
        contextActions={contextActions}
        clearSelectedRows={toggleCleared}
        pagination
      />
    </Box>
  );
};

export default Devices;
