import { IWorkspaceSetting } from 'shared/model/workspace.model';

export const workspaceIsIbc = (settings: IWorkspaceSetting[]) =>
  settings.some(
    setting =>
      (setting.key === 'is_ibc' && setting.value === 'true') ||
      (setting.key === 'useCase' && setting.value === 'ibc')
  );

export const workspaceIsSilo = (settings: IWorkspaceSetting[]) =>
  settings.some(
    setting =>
      (setting.key === 'is_silo' && setting.value === 'true') ||
      (setting.key === 'useCase' && setting.value === 'silo_industry')
  );

export const useWorkspaceType = (settings: IWorkspaceSetting[]) =>
  settings.find(s => s.key === 'useCase')?.value ?? 'silo';

export const workspaceUseApi = (settings: IWorkspaceSetting[]) =>
  settings.some(setting => setting.key === 'use_api' && setting.value === 'true');

export const getUseCase = (settings: IWorkspaceSetting[]) =>
  settings.find(setting => setting.key === 'useCase')?.value;

export const getSupportEmail = (settings: IWorkspaceSetting[]) => {
  const setting = settings.find(setting => setting.key === 'support_email');
  if (setting) {
    return setting.value;
  }
  return 'support@nanolike.com';
};
