import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Description, LibraryBooks } from '@material-ui/icons';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import VpnLockIcon from '@material-ui/icons/VpnLock';
import { IRootState } from 'config/store';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PrivateComponent from 'shared/auth/privateComponent';
import { logout } from 'shared/reducers/authenticationSlice';
import { closeHeaderMenu, openTermsOfUse } from 'shared/reducers/layoutSlice';
import { openChat } from 'shared/utils/chat.utils';
import { useWorkspaceType } from 'shared/utils/workspace-utils';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    icon: {
      marginRight: theme.spacing(1)
    },
    exit: {
      color: theme.palette.secondary.main
    }
  });
});

const HeaderMenu = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const headerMenuOpen = useSelector(({ layout }: IRootState) => layout.headerMenuOpen);
  const settings = useSelector(({ settings }: IRootState) => settings.settings);
  const workspaceSettings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const useCase = useWorkspaceType(workspaceSettings);

  const handleClose = () => {
    dispatch(closeHeaderMenu());
  };

  const onLogout = () => {
    dispatch(logout());
    handleClose();
  };

  const handleShowTermsOfUse = () => {
    dispatch(openTermsOfUse());
    handleClose();
  };

  const openHelp = () => {
    openChat();
    handleClose();
  };

  return (
    <Menu
      id="menu-appbar"
      anchorEl={headerMenuOpen}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={Boolean(headerMenuOpen)}
      onClose={handleClose}
    >
      <MenuItem component={Link} to="/user-profile" onClick={handleClose}>
        <AccountBoxIcon className={classes.icon} />
        {t('edit_profile')}
      </MenuItem>
      <MenuItem component={Link} to="/user-password" onClick={handleClose}>
        <VpnLockIcon className={classes.icon} />
        {t('edit_password')}
      </MenuItem>
      {/* @deprecated
      <MenuItem component={Link} to="/api-keys" onClick={handleClose}>
        <VpnKeyIcon className={classes.icon} />
        {t('api_keys')}
      </MenuItem>*/}
      {(!settings || !settings.config_activated) && (
        <MenuItem onClick={openHelp}>
          <LiveHelpIcon className={classes.icon} />
          {t('help')}
        </MenuItem>
      )}
      {workspaceSettings?.find(setting => setting.key === 'webhook_doc')?.value === 'true' &&
        useCase.includes('silo') && (
          <PrivateComponent resource="developer_doc" operation={['READ']}>
            <MenuItem onClick={() => window.open(`webhook-${useCase}.html`)}>
              <Description className={classes.icon} />
              {t('webhook_documentation')}
            </MenuItem>
          </PrivateComponent>
        )}
      {workspaceSettings?.find(setting => setting.key === 'api_doc')?.value === 'true' &&
        useCase.includes('silo') && (
          <PrivateComponent resource="developer_doc" operation={['READ']}>
            <MenuItem
              onClick={() =>
                window.open(`//developers.nanolike.${window.location.host.split('.')[2]}`)
              }
            >
              <LibraryBooks className={classes.icon} />
              {t('api_documentation')}
            </MenuItem>
          </PrivateComponent>
        )}
      {/* @deprecated
      <MenuItem component={Link} to="/api-doc" onClick={handleClose}>
        <LibraryBooksIcon className={classes.icon} />
        {t('api_doc')}
      </MenuItem>*/}
      <MenuItem onClick={handleShowTermsOfUse}>
        <MenuBookIcon className={classes.icon} />
        {t('menu_title')}
      </MenuItem>
      <MenuItem component={Link} to="/" onClick={onLogout} className={classes.exit}>
        <ExitToAppIcon className={classes.icon} />
        {t('log_out')}
      </MenuItem>
    </Menu>
  );
};

export default HeaderMenu;
