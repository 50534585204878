import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import axios, { AxiosResponse } from 'axios';
import { getEnvApiUrl, getEnvWorkspace } from 'config/env';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import { changeChatLang } from 'shared/utils/chat.utils';
import Loading from 'shared/widgets/loading';
import RegisterCard from './registerCard';
import LeftLanding from '../../shared/widgets/leftLanding';

const apiUrl = getEnvApiUrl();
axios.defaults.headers.common.WORKSPACE = getEnvWorkspace();
interface IRegisterResponse {
  group: string[];
  mail: string;
  lang: string;
  first_name: string;
  last_name: string;
  workspace: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      height: `100vh`,
      '&>*': {
        width: '100%'
      }
    },
    box: {
      // height: `100vh`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    }
  })
);
interface IRegisterState {
  loading: boolean;
  signUp: boolean;
  errorMessage: string;
  email: string;
  first_name: string;
  last_name: string;
  lang: string;
}

const initialState: IRegisterState = {
  loading: false,
  signUp: false,
  errorMessage: '',
  email: '',
  first_name: '',
  last_name: '',
  lang: ''
};

const Register = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [state, setState] = useState<IRegisterState>(initialState);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');

  useEffect(() => {
    const load = async () => {
      try {
        setState({
          ...initialState,
          loading: true
        });

        const response: AxiosResponse<IRegisterResponse> = await axios.get(
          `${apiUrl}/register?token=${token}`
        );
        const data = response.data;
        const lang = data.lang;
        setState({
          ...initialState,
          loading: false,
          email: data.mail,
          first_name: data?.first_name,
          last_name: data?.last_name,
          lang
        });
        if (lang !== i18n.resolvedLanguage) {
          i18n.changeLanguage(lang);
          changeChatLang(lang);
        }
      } catch (error) {
        const errorMsg = getRequestErrorMessage(error);
        setState({
          ...initialState,
          loading: false,
          errorMessage: errorMsg
        });
      }
    };
    load();
  }, [token, dispatch, i18n]);

  if (state.errorMessage) {
    return <Redirect to="/" />;
  }

  return (
    <Grid container className={classes.root} spacing={0}>
      <LeftLanding />
      <Grid item sm={6}>
        <Box className={classes.box} p={4}>
          {state.email ? <RegisterCard {...state} /> : state.loading ? <Loading /> : null}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Register;
